<template>
  <v-container fluid data-cy="container" style="padding-top: 0px">
    <v-card class="pa-3 wrapper" style="border: none" data-cy="main-card">
      <v-tabs v-model="tab" style="border-bottom: 2px solid" data-cy="tabs">
        <v-tab :key="0" :active="activeTab === 0" @click="activeTab = 0" data-cy="tab-overview">
          <i class="fa-regular fa-file-invoice"></i>
          &nbsp; Product
        </v-tab>
        <v-tab :disabled="!viewBom" :key="1" :active="activeTab === 1" @click="activeTab = 1" data-cy="tab-bom">
          <i class="fa-duotone fa-solid fa-pen-to-square"></i>
          &nbsp; Edit BOM
        </v-tab>
        <v-tab :disabled="!viewBom" :key="2" :active="activeTab === 2" @click="activeTab = 2" data-cy="tab-bom">
          <i class="fa-sharp-duotone fa-solid fa-code-compare"></i>
          &nbsp; Compare
        </v-tab>
        <v-tab :key="3" :active="activeTab === 3" @click="activeTab = 3" data-cy="tab-bom">
          <i class="fa-duotone fa-solid fa-network-wired"></i>
          &nbsp; Structure
        </v-tab>
        <v-tab :key="4" :active="activeTab === 4" @click="activeTab = 4" data-cy="tab-bom">
          <i class="fa-duotone fa-solid fa-image"></i>
          &nbsp; Drawings
        </v-tab>
        <v-spacer></v-spacer>
        <v-text-field
          class="mr-3"
          :style="{ maxWidth: '450px' }"
          v-model="generation_item"
          density="compact"
          label="PN"
          variant="underlined"
          hide-details
          single-line
          data-cy="search-field"
          @keydown.stop
          @keyup.enter="generation_item.length !== 0 && generate()"
          :loading="loadingData"
        ></v-text-field>
        <v-btn class="btn-orange" :disabled="generation_item.length === 0" @click="generate" data-cy="new-button">Generate</v-btn>
      </v-tabs>
      <div v-if="tab === 0" data-cy="tab-content-overview">
        <GenerateViewBom
          :viewBom="mergedViewBom"
          :odooAttributes="odooAttributes"
          :product_info="product_info"
          :generation_item="generation_item"
          :loadingData="loadingData"
          :polarityData="polarityData"
          :originalBom="viewBom"
          :items="items"
        />
      </div>
      <div v-else-if="tab === 1" data-cy="tab-content-bom">
        <GenerateEditBom :viewBom="viewBom" :loadingData="loadingData" />
      </div>
      <div v-else-if="tab === 2" data-cy="tab-content-bom">
        <GenerateCompare :currentBom="mergedViewBom" :generation_item="generation_item" :loadingData="loadingData" />
      </div>

      <div v-else-if="tab === 3" data-cy="tab-content-bom">
        <v-row v-if="convertedItems.length && product_info.length">
          <v-col cols="12" md="12" class="mt-4">
            <h2>Product info:</h2>

            <v-col cols="12" md="12" style="padding: 0px 12px">
              <v-text-field style="pointer-events: none" readonly v-model="product_info" label="Description" variant="underlined"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols="12" md="4" class="tree">
            <div class="structure-container">
              <n-space vertical>
                <n-tree :show-line="true" :default-expanded-keys="defaultExpandedKeys" :data="convertedItems" :node-props="nodeProps" :render-label="renderCustomLabel" />
              </n-space>
            </div>
          </v-col>

          <v-col cols="12" md="8" class="details">
            <div class="details-container">
              <div class="text-details" data-cy="details-content">
                <div>
                  <v-row>
                    <!-- Attributes Section -->
                    <v-col cols="12" md="12" style="padding-bottom: 0px" v-if="item_details?.attributeValues?.length || item_details?.availableAttributeValues?.length">
                      <v-card-title>
                        <span class="mr-2" @click="isExpandedAttributes = !isExpandedAttributes" style="cursor: pointer">
                          <i :class="isExpandedAttributes ? 'fa-regular fa-square-minus' : 'fa-solid fa-square-plus'"></i>
                        </span>

                        Attributes
                      </v-card-title>

                      <v-expand-transition>
                        <v-card-text v-if="isExpandedAttributes">
                          <!-- Local Attributes -->
                          <template v-if="item_details?.attributeValues?.length">
                            <v-card-title class="d-flex align-center" @click="isExpandedLocal = !isExpandedLocal" style="cursor: pointer">
                              <span class="mr-2">
                                <i :class="isExpandedLocal ? 'fa-regular fa-square-minus' : 'fa-solid fa-square-plus'"></i>
                              </span>
                              <span class="sub-title">Local</span>
                            </v-card-title>

                            <v-expand-transition>
                              <v-card-text v-if="isExpandedLocal">
                                <v-table density="compact">
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th style="min-width: 130px">Name</th>
                                        <th>Value</th>
                                        <th>UoM</th>
                                        <th>Source</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(attr, index) in item_details.attributeValues" :key="index">
                                        <td>{{ attr.description }}</td>
                                        <td v-if="attr.attributeId === 'error'">
                                          <span class="error-attr">⚠️ {{ attr.value }}</span>
                                        </td>
                                        <td v-else>{{ attr.value }}</td>
                                        <td>{{ attr.uom }}</td>
                                        <td>
                                          <span v-if="attr?.inheritedFrom?.name" class="inheritedFrom">{{ attr?.inheritedFrom?.name }}</span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-table>
                              </v-card-text>
                            </v-expand-transition>
                          </template>

                          <!-- <v-divider class="my-2" v-if="item_details?.attributeValues?.length && item_details?.availableAttributeValues?.length"></v-divider> -->

                          <!-- Parent Attributes -->
                          <template v-if="item_details?.availableAttributeValues?.length">
                            <v-card-title class="d-flex align-center" @click="isExpandedParent = !isExpandedParent" style="cursor: pointer">
                              <span class="mr-2">
                                <i :class="isExpandedParent ? 'fa-regular fa-square-minus' : 'fa-solid fa-square-plus'"></i>
                              </span>
                              <span class="sub-title">Parent</span>
                            </v-card-title>

                            <v-expand-transition>
                              <v-card-text v-if="isExpandedParent">
                                <v-table density="compact">
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th style="min-width: 130px">Name</th>
                                        <th>Value</th>
                                        <th>UoM</th>
                                        <th>Source</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(attr, index) in item_details.availableAttributeValues" :key="index">
                                        <td>{{ attr.description }}</td>
                                        <td v-if="attr.attributeId === 'error'">
                                          <span class="error-attr">⚠️ {{ attr.value }}</span>
                                        </td>
                                        <td v-else>{{ attr.value }}</td>
                                        <td>{{ attr.uom }}</td>
                                        <td>
                                          <span v-if="attr?.inheritedFrom?.name" class="inheritedFrom">{{ attr?.inheritedFrom?.name }}</span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-table>
                              </v-card-text>
                            </v-expand-transition>
                          </template>
                        </v-card-text>
                      </v-expand-transition>
                    </v-col>

                    <!-- Materials Section -->
                    <v-col cols="12" md="12" style="padding-bottom: 0px; padding-top: 0px" v-if="item_details?.materials?.length">
                      <v-card-title>
                        <span class="mr-2" @click="isExpandedMaterial = !isExpandedMaterial" style="cursor: pointer">
                          <i :class="isExpandedMaterial ? 'fa-regular fa-square-minus' : 'fa-solid fa-square-plus'"></i>
                        </span>
                        Materials [{{ item_details.materials.length }}]
                      </v-card-title>

                      <v-expand-transition>
                        <v-card-text v-if="isExpandedMaterial">
                          <template v-for="(material, materialIndex) in item_details.materials" :key="materialIndex">
                            <v-card-title class="d-flex align-center" @click="toggleMaterial(materialIndex)" style="cursor: pointer">
                              <span class="mr-2">
                                <i :class="expandedMaterials[materialIndex] ? 'fa-regular fa-square-minus' : 'fa-solid fa-square-plus'"></i>
                              </span>

                              <span class="sub-title">
                                {{ material.odooMaterial?.pn }}
                                <n-tooltip v-if="material.virtual" trigger="hover" placement="top">
                                  <template #trigger>
                                    <span>*</span>
                                  </template>
                                  Virtual material based on
                                  <strong>{{ material.originalPn }}</strong>
                                  <hr style="margin-bottom: 4px" />
                                  Material does not exist in the system yet.
                                  <br />
                                  It will be added to the system after BOM is created in Odoo
                                </n-tooltip>
                              </span>
                            </v-card-title>

                            <v-expand-transition>
                              <v-card-text v-if="expandedMaterials[materialIndex]">
                                <v-table density="compact">
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th style="min-width: 130px">Name</th>
                                        <th>Value</th>
                                        <th>UoM</th>
                                        <th>Source</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(attr, index) in material.attributes" :key="index">
                                        <td>{{ attr.description }}</td>
                                        <td v-if="attr.attributeId === 'error'">
                                          <span class="error-attr">⚠️ {{ attr.value }}</span>
                                        </td>
                                        <td v-else>{{ attr.value }}</td>
                                        <td>{{ attr.uom }}</td>
                                        <td>
                                          <span v-if="attr?.inheritedFrom?.name" class="inheritedFrom">{{ attr?.inheritedFrom?.name }}</span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-table>
                              </v-card-text>
                            </v-expand-transition>

                            <v-divider class="my-2" v-if="materialIndex < item_details.materials.length - 1"></v-divider>
                          </template>
                        </v-card-text>
                      </v-expand-transition>
                    </v-col>

                    <!-- No Data Section -->
                    <v-col cols="12" md="12" v-if="!selected_item || !hasAttributes">
                      <div class="text-center no-data" data-cy="details-content">
                        <h2 data-cy="details-title">No data</h2>
                        <p data-cy="details-description">This element does not have any attributes.</p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <div v-else data-cy="tab-content-details">
          <div class="text-center no-data" data-cy="details-content" v-if="!loadingData">
            <h2 data-cy="details-title">No Component Data</h2>
            <p data-cy="details-description">
              Please write
              <strong>PN</strong>
              and press
              <span class="mini-btn"><strong style="color: orange">GENERATE</strong></span>
              button
            </p>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="12" md="12">
                <v-skeleton-loader type="heading" :loading="loadingData" rows="5" row-height="30" class="mt-5" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-skeleton-loader type="article" :loading="loadingData" rows="5" row-height="30" class="mt-5" />
              </v-col>
              <v-col cols="12" md="8">
                <v-skeleton-loader type="heading, table-row-divider@6" :loading="loadingData" rows="5" row-height="30" class="mt-5" />
              </v-col>
            </v-row>
          </div>
        </div>
      </div>

      <div v-else-if="tab === 4">
        <GenerateDrawings :items="items" :polarityData="polarityData" />
      </div>
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent, render, h } from 'vue';
  import { useComponentsTypeStore } from '@/store/componentsTypesStore';
  import { useProductStore } from '@/store/productStore';
  import assemblyService from '@/services/api/assemblyService';
  import componentService from '@/services/api/componentService';
  import GenerateViewBom from '@/views/generate/GenerateViewBom.vue';
  import GenerateEditBom from '@/views/generate/GenerateEditBom.vue';
  import GenerateDrawings from '@/views/generate/GenerateDrawings.vue';
  import GenerateCompare from '@/components/bom/CompareBoms.vue';

  import { NTooltip } from 'naive-ui';

  export default defineComponent({
    name: 'Generate',
    components: {
      GenerateViewBom,
      GenerateEditBom,
      GenerateCompare,
      GenerateDrawings,
    },
    data() {
      return {
        tab: 0,
        generation_item: '',
        selected_item: null as any,
        item_details: null as any,
        item_pn: null as any,
        product_info: null as any,
        items: [] as any,
        isExpandedParent: false,
        isExpandedLocal: true,
        isExpandedMaterial: true,
        activeTab: 0,
        viewBom: null as any,
        viewBomEdit: null as any,
        convertedItems: [] as any[],
        defaultExpandedKeys: [] as any[],
        loadingData: false,
        expandedMaterials: [] as boolean[],
        isExpandedAttributes: true,
        components: [] as any[],
        odooAttributes: [] as any[],
        polarityData: [] as any[],
      };
    },
    watch: {
      selected_item(val: number) {
        this.update_details(val);
      },
      tab(newVal: number) {
        if (newVal === 0) {
          this.addParentKeys(this.convertedItems, this.defaultExpandedKeys);
        }
      },
    },
    computed: {
      hasAttributes() {
        return this.item_details?.attributeValues?.length > 0 || this.item_details?.availableAttributeValues?.length > 0;
      },
      mergedViewBom() {
        if (!this.viewBomEdit || !this.viewBomEdit.length) return [];

        const merged = this.viewBomEdit.reduce((acc: any, item: any) => {
          const existingItem = acc.find((i: any) => i.materials[0].pn === item.materials[0].pn);

          if (existingItem) {
            existingItem.quantity = (existingItem.quantity || 0) + (item.quantity || 0);
            existingItem.cutQuantity = (existingItem.cutQuantity || 0) + (item.cutQuantity || 0);
            existingItem.cutSize = (existingItem.cutSize || 0) + (item.cutSize || 0);
          } else {
            acc.push({ ...item });
          }

          return acc;
        }, []);

        return merged.map((item: any) => ({
          ...item,
          quantity: parseFloat((item.quantity || 0).toFixed(2)),
          cutQuantity: parseFloat((item.cutQuantity || 0).toFixed(2)),
          cutSize: parseFloat((item.cutSize || 0).toFixed(2)),
        }));
      },
    },
    mounted() {
      this.getComponentsList();
      sessionStorage.removeItem('renderLink');
    },
    methods: {
      async getComponentsList() {
        this.loadingData = true;
        const componentTypesStore = useComponentsTypeStore();
        try {
          const response = await componentService.getComponentsList();
          this.components = response;
          componentTypesStore.setComponentsTypesData(response);
        } catch (error) {
          this.$log.showError(`Failed to fetch component: ${error}`);
        } finally {
          this.loadingData = false;
        }
      },
      toggleMaterial(index: number) {
        this.expandedMaterials[index] = !this.expandedMaterials[index];
        this.expandedMaterials = [...this.expandedMaterials];
      },
      update_details(val: any) {
        this.item_details = null;

        const findItemById = (items: any[], id: number): any => {
          for (const item of items) {
            if (item.id === id) {
              return item;
            }
            if (item.children) {
              const found = findItemById(item.children, id);
              if (found) return found;
            }
          }
          return null;
        };

        this.item_details = findItemById(this.items, val.key);
      },
      generate() {
        this.clear();
        this.tab = 0;
        this.activeTab = 0;
        this.item_pn = this.generation_item;
        this.getTree(this.item_pn);
      },
      convertToNaiveTree(vuetifyItems: any[]): any[] {
        return vuetifyItems.map((item) => {
          const node: any = {
            key: item.id,
            // label: item.name,
            label: item.name ? `${item.name} (${item.componentTypeName})` : item.componentTypeName || 'Unknown Component',
          };

          const matchedComponent = this.components.find((component) => component.id === item.componentTypeId);

          if (matchedComponent) {
            const category = matchedComponent.category;

            // node.label += ` (${category})`;

            const isLeaf = !item.children || item.children.length === 0;

            if (category === 'Raw material') {
              const rawMaterialCount = item.materials ? item.materials.length : 0;
              if (rawMaterialCount > 0) node.label += ` 🟢 (${rawMaterialCount} raw materials)`;
              else if (!isLeaf) node.label += ` 🟣 (Sub-assembly)`;
              else node.label += ` 🔴 (No raw materials found)`;
            }
          }

          if (item.children && item.children.length > 0) {
            node.children = this.convertToNaiveTree(item.children);
          }

          return node;
        });
      },
      renderCustomLabel(option: any) {
        const label = option.option.label.trim();
        const labelParts = label.match(/^(.*?)([🟢🟣🔴].*)?$/);
        const mainLabel = labelParts?.[1]?.trim() || 'Missing Label';
        const icon = (labelParts?.[2] || '').replace(/\(.*?\)/, '').trim();
        const tooltipContent = labelParts?.[2]?.trim() || '';

        return h('div', { class: 'custom-label' }, [
          h('span', {}, mainLabel),
          icon && tooltipContent
            ? h(
                NTooltip,
                { trigger: 'hover', placement: 'top' },
                {
                  trigger: () => h('span', icon),
                  default: () => tooltipContent,
                }
              )
            : null,
        ]);
      },
      isLastLeaf(item: any): boolean {
        return !item.children || item.children.every((child: any) => !child.children || child.children.length === 0);
      },
      addParentKeys(tree: any[], expandedKeys: any[]) {
        const addParents = (node: any, keyPath: any[] = []) => {
          const currentPath = [...keyPath, node.key];

          if (expandedKeys.includes(node.key)) {
            expandedKeys.push(...keyPath);
          }

          if (node.children && node.children.length) {
            node.children.forEach((child: any) => addParents(child, currentPath));
          }
        };

        tree.forEach((rootNode) => addParents(rootNode));
      },

      async getTree(item: any) {
        this.loadingData = true;
        try {
          const response = await assemblyService.generateAssembly(item);
          if (response) {
            const data = response.tree;
            this.items = [data];
            this.product_info = response?.description;
            this.viewBom = response?.bom;
            this.viewBomEdit = response?.bom;
            this.convertedItems = this.convertToNaiveTree(this.items);
            this.odooAttributes = response?.tree?.attributeValues
              .filter((attr: { value: string }) => attr.value !== 'N/A')
              .map((attr: { value: string; description: string }) => ({
                value: attr.value,
                name: attr.description,
              }));
            this.polarityData = response?.polarityMap;
            useProductStore().setPolarity(this.polarityData);
          }
          this.loadingData = false;
          console.log('response', this.polarityData);
        } catch (error: any) {
          this.loadingData = false;
          this.$error.view(error);
        }
      },

      nodeProps({ option }: { option: any }) {
        return {
          onClick: () => {
            this.handleTitleClick(option);
            const keyIndex = this.defaultExpandedKeys.indexOf(option.key);
            if (keyIndex > -1) {
              this.defaultExpandedKeys.splice(keyIndex, 1);
            } else {
              this.defaultExpandedKeys.push(option.key);
            }
          },
          onContextmenu: (event: MouseEvent) => {
            //event.preventDefault();
          },
          class: {
            'selected-node': this.selected_item && this.selected_item.key === option.key,
            'bold-node': this.selected_item && this.selected_item.key === option.key,
          },
        };
      },
      handleTitleClick(item: any) {
        if (this.selected_item && this.selected_item.key === item.key) {
          return;
        }
        this.selected_item = item;
        this.item_details = item;
      },
      clear() {
        this.items = [];
        this.item_details = null;
        this.product_info = null;
        this.convertedItems = [];
        sessionStorage.removeItem('renderID');
        sessionStorage.removeItem('pdfUrl');
        sessionStorage.removeItem('pngUrl');
        sessionStorage.removeItem('vsdxUrl');
      },
    },
  });
</script>

<style scoped>
  .wrapper {
    min-height: calc(100vh - 120px);
  }
  .item-info {
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }
  .info {
    padding-bottom: 0px;
  }
  .tree {
    border-right: 1px solid #e0e0e0;
    padding-top: 16px !important;
  }
  .details {
    border-left: 1px solid #e0e0e0;
  }
  .no-data {
    padding-top: 50px;
  }
  .product-description {
    font-weight: 400;
    font-size: 1rem;
  }
  .custom-title {
    cursor: pointer;
    font-weight: bold;
  }
  .inheritedFrom {
    color: white;
    background-color: cadetblue;
    padding: 2px 10px;
    border-radius: 50px;
  }
  .error-attr {
    color: red;
  }
  .material-title {
    font-size: 0.8rem;
    font-weight: 400;
  }
  .sub-title {
    font-size: 1rem;
    font-weight: 400;
  }
  :deep(.selected-node > div) {
    font-weight: bold;
  }
  :deep(.n-tree .n-tree-node-indent.n-tree-node-indent--show-line::before) {
    border-left: 1px solid rgb(160, 160, 160);
  }
  /* :deep(.n-tree .n-tree-node-wrapper) {
    font-size: 16px;
  }
  :deep(.n-tree-node) {
    font-size: 14px;
  } */
  .fa-square-plus,
  .fa-square-minus {
    color: #696969;
  }
  .mini-btn {
    font-size: 0.8rem;
    border: 1px solid orange;
    padding: 2px 5px;
    border-radius: 5px;
  }
</style>
